<template>
    <div class="page">
        <div class="hzynav w-1350">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path: '/bdswfw'}">{{title}}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>咨询服务</el-breadcrumb-item> -->
                <!-- <el-breadcrumb-item>{{title}}</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <div class="conter w-1350">
            <div class="text">{{detailsList.name}}</div>            
            <div class="tool-p" v-html="detailsList.content">
                图文介绍
            </div>
            
            <div class="anniu-wrap">
                <div class="lianjie" v-if="detailsList.link">合作伙伴网站链接：<span  @click="goUrl(detailsList.link)">{{detailsList.link}}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getdetails } from "../../api/index"
    export default {
        name:'state_list',
        data() {
            return {
                id:'',
                title:'',
                detailsList:[]
                
            }
        },
        created() {
            this.id=this.$route.query.id
            if(this.$route.query.key==0){
                this.title='合作伙伴'
            }else{
                this.title='本地商务服务'
            }
            this.getdetails()         
        },
        methods: {
            // 跳转外链
            goUrl(url){
               window.open(url) 
            },
            getdetails(){
                getdetails({
                    id:this.id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.detailsList=res.data
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{margin: 35px auto;padding-left: 15px;box-sizing: border-box;}
    .conter{
        margin: auto;margin-bottom:140px;padding:70px 100px;box-sizing: border-box;background:#fff;
        .text{font-size: 28px;color: #555555;text-align: center;margin-bottom: 15px;}
        
        .tool-p{
            color: #666666;
            font-size: 16px;
            line-height: 32px;
            img{width: 100%;}
        }
        .anniu-wrap{
            padding-top: 40px;
            .lianjie{
                font-size: 18px;
                color: #FE6D00;
                &:hover{
                    span{text-decoration: underline;cursor: pointer;}
                }
            }
            .anniu-p{
                color:#555555;
                font-size: 22px;
            }
            .anniu{
                display: flex;flex-wrap: wrap;
                .button{
                    width: 15%;height: 43px;position: relative;cursor: pointer;margin-right:2%;margin-top:30px;
                    img{width: 100%;height: 100%;}
                    p{
                        width: 100%;height: 100%;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;
                        display: flex;align-items:center;justify-content: center;
                        i{font-size: 25px;padding-top: 3px;}
                    }
                    &:nth-child(6n+0){margin-right: 0;}
                } 

            }
        }

    }

</style>